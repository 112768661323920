import "./cssVariables.css";

export default function CssVariablesScope() {
  return (
    <div>
      <span> checking css variables scope</span>
      {/* <div class="outerDiv"> */}
      <div class="innerDiv">
        <span class="outerDiv  textSpan ">hello</span>
      </div>
    </div>
    // </div>
  );
}
