import CssVariablesScope from "~/components/concepts/cssVariables";

export default function FontTest() {
  return (
    <>
      <div>
        <span class="text-small">small</span>
        <br />
        <span class="text-small !font-normal">normal</span>
        <br />

        <span class="!font- text-small">bold</span>
      </div>
      <CssVariablesScope />
    </>
  );
}
